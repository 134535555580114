<template>
    <div class="healthgift">
        <vue-header title="健康红包" :fixed="true"/>
        <div class="healthgift-body" v-if="red_info">
            <div class="healthgift-box">
                <div class="healthgift-img-title">
                    <img src="../../../../images/redEnvelope/gift-health.png"/>
                </div>
                <div class="healthgift-wan">
                    {{red_info.blessing}}
                </div>
                <div class="healthgift-img-bg flex">
                    <div class="flex-1 flex flex-y">可领取红包数</div>
                    <div class="flex flex-y"><span class="s">{{red_info.test_count-red_info.lingqu_count}}</span><span class="t">次</span></div>
                </div>
                <div class="healthgift-img-bg flex">
                    <div class="flex-1 flex flex-y">健康评估次数</div>
                    <div class="flex flex-y"><span class="s">{{red_info.can_test}}</span><span class="t">次</span></div>
                </div>
            </div>
        </div>
        <div class="healthgift-explain">
            <div class="healthgift-title"> - 了解健康红包 - </div>
            <div class="lop">每个健康红包，可领取到不同次数的健康评估卡。<span>（限一人领取）</span></div>
            <div class="lop">健康评估卡可用于进行【智能中医健康评估】，获取您的健康评估报告，时刻了解自己的健康状态</div>
            <div class="lop">可前往<span>【泉脉健康】</span>公众号，<span>【我的泉脉】</span>—<span>【充值卡包】</span>查看</div>
            <div class="lop"><span>未领取的健康红包，可转发给您的微信好友，由他领取并前往评估。</span></div>
        </div>
        <div class="heah-h1" v-if="info">
            <div>附上我的名片</div>
            <!-- <div>(可长按保存)</div> -->
            <div class="flex flex-x">
                <div class="heah-img">
                    <!-- <img :src="`https://api.quanmaikeji.com/user/v1/api/user/card?Avatar=&DefaultAvatar=https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/default_avatar.png&Background=https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/sale_background3.png&Mobile=${info.phone}&Name=${info.name||info.nikeName}&Company=${info.company_name}&Job=`"/> -->
                    <div class="heah-png">
                        <div>{{info.name||info.nikeName}}</div>
                        <div>{{info.phone}}</div>
                        <div>{{info.company_name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="estimate flex flex-x flex-y" @click="estimateFun">前往评估</div>
    </div>
</template>
<script>
    import vueHeader from '@/components/header';
    import { mapState, mapActions, mapMutations } from "vuex";
    import { Dialog } from 'vant';
    export default {
        name: 'gift',
        components:{
            vueHeader
        },
        created:function(){
            if(this.$route.query.no){
                Dialog.alert({
                    title:'红包提示',
                    message: '您今天已经领过红包了，欢迎明天再来领取。',
                });
            }
            this.axiosRedInfo({
                data:{
                    hongbao_code:this.$route.params.id
                },
                fun:()=>{}
            });
        },
        computed:{
            ...mapState('redEnvelope',['red_info']),
            ...mapState('profile',['info']),
        },
        methods: {
            estimateFun(){
                this.axiosRedCanReport({
                    data:{
                        use_hongbao:1,
                        hongbao_code:this.$route.params.id,
                    },
                    fun:(data)=>{
                        this.$router.push({
                            path:'/confirmAssess/15',
                            query:{
                                uid:this.info.id,
                                red:data.test_code?data.test_code:false
                            }
                        });
                    }
                })
            },
            ...mapActions('redEnvelope',['axiosRedInfo','axiosRedCanReport']),
        }
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'gift';
</style>